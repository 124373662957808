import React from 'react';
import { graphql } from 'gatsby';
import * as queryString from 'query-string';

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import Section from '../../components/shared/Section/Section';
import BlogSearch from '../../components/BlogSearch/BlogSearch';
import Pagination from '../../components/Pagination/Pagination';
import PageHeader from '../../components/shared/PageHeader/PageHeader';

const BlogListing = (props) => {
  const { data, navigate, location } = props;
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;
  const localSearchBlog = data.localSearchBlog;
  const { currentPage, numPages, totalPosts } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;
  const { search } = queryString.parse(location.search);

  return (
    <Default
      location={props.location}
      title={siteTitle}
      heading='Blog posts'
    >
      <SEO title='Blog posts' />

      <PageHeader
        heading='Blog posts'
        description='Read some of the latest ParkyGames articles, channel news, game reviews and other ramblings'
      />

      <Section accent>
        <BlogSearch
          currentPage={currentPage}
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
          summary={search !== undefined ? `Showing results for "${search}"` : `Showing page ${currentPage} of ${numPages} · Total ${totalPosts} articles`}
        />

        <Pagination
          isFirst={isFirst}
          prevPage={prevPage}
          currentPage={currentPage}
          isLast={isLast}
          nextPage={nextPage}
          numPages={numPages}
        />
      </Section>
    </Default>
  );
};

export default BlogListing;

export const blogListingQuery = graphql`
  query blogListingQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            tags
            path
          }
        }
      }
    }
  }
`;