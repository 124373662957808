import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Component Imports
import TagList from '../TagList/TagList';

// Style Imports
import './ArticleList.scss';

// Asset Imports
import Avatar from '../../../assets/images/content/youtube-avatar.jpg';

/**
 * Article card item - used as a direct child to <ArticleList> only.
*/

const ArticleListItem = ({
  slug,
  title,
  date,
  description,
  author,
  tags,
}) => {
  return (
    <li className='article-list__item'>
      <article className='article-list__article'>
        <h2 className='article-list__heading'>
          <Link className='article-list__link' to={`/blog/${slug}`}>
            {title}
          </Link>
        </h2>

        {(date || author) && (
          <div className='article-list__info'>
            {author && (
              <p className='article-list__info-item'>
                {author === 'ParkyGames' && (
                  <span className='article-list__avatar-wrapper'>
                    <img className='article-list__avatar' src={Avatar} alt='' aria-hidden='true' />
                  </span>
                )}
                {author}
              </p>
            )}
            {date && (
              <p className='article-list__info-item'>{date}</p>
            )}
          </div>
        )}

        <p className='article-list__description'>
          <span dangerouslySetInnerHTML={{__html: description}} />...
          <Link className='article-list__link' to={`/blog/${slug}`}>Read more...</Link>
        </p>

        {tags && tags.length && (
          <TagList
            tags={tags}
            prepend='Tags:'
          />
        )}
      </article>
    </li>
  );
};

ArticleListItem.propTypes = {
  /**
   * The url slug used to generate a link to the blog post
  */
  slug: PropTypes.string.isRequired,
  /**
   * The blog post's main heading
  */
  title: PropTypes.string.isRequired,
  /**
   * The date the blog post was created
  */
  date: PropTypes.string,
  /**
   * A short description for the blog post
  */
  description: PropTypes.string,
  /**
   * Name of the blog post author
  */
  author: PropTypes.string,
  /**
   * Any tags added to the post
  */
  tags: PropTypes.array,
};

export default ArticleListItem;