import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './ArticleList.scss';

/**
 * An unordered list of article card items - use wherever a list of blog posts is required. Use <ArticleListItem> as a child.
*/

const ArticleList = ({
  children,
  id,
}) => {
  return (
    <ul className='article-list' id={id}>
      {children}
    </ul>
  );
};

ArticleList.propTypes = {
  /**
   * Use one or many <ArticleList.Item>
  */
  children: PropTypes.node.isRequired,
  /**
   * Unique id for article listing used for aria attribute referencing
  */
  id: PropTypes.string,
};

export default ArticleList;